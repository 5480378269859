import personalizedSearchQuery from '@api/graphql/personalizedSearch';
import axios from '@axios';
import { Experiments } from '@typings/redux';
import { StateCodes } from '@typings/states';
import { generateSeed } from '@utils/generateSeed';
import sortParam from '@utils/sortParam';

type SearchType = 'distance' | 'featured' | 'recommended' | 'review-rating';

type ExperimentHeaders = { [key: string]: string };

export interface SearchArgs {
	categoryGuid: string;
	city: string;
	filters?: string[];
	fixedPageSize?: boolean;
	limit: number;
	page: number;
	seed: string;
	sort?: SearchType;
	state: StateCodes;
	visitorId?: string;
	experiments?: Experiments;
}

export const search = async (
	args: SearchArgs,
	experimentHeaders?: ExperimentHeaders,
): Promise<API.SearchResponse> => {
	const {
		categoryGuid,
		city,
		filters,
		fixedPageSize,
		limit,
		page,
		seed,
		sort,
		state,
	} = args;

	const searchFilters = filters || [categoryGuid];
	const apiPage = page > 0 ? page - 1 : 0;

	const data = {
		query: personalizedSearchQuery(),
		variables: {
			categoryGuid,
			city,
			filters: searchFilters,
			limit: limit || 30,
			page: apiPage,
			search_fallback_offset: apiPage * 30,
			seed: seed || generateSeed(),
			sort: sortParam(sort),
			state,
			fixedPageSize: fixedPageSize || false,
		},
	};

	const response = await axios.post<API.SearchResponseData>(data, {
		headers: experimentHeaders,
	});

	return response;
};

export default search;
